<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["onlineUser"]),
  },
  computed: {
    ...mapGetters(["getOnlineUser"]),
  },
  mounted() {
    if (!this.getOnlineUser.email_verified_at) this.onlineUser();
  },
};
</script>